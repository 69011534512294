import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  lang,
  description,
  image,
  pathname,
  article,
  author,
  keywords,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername,
          defaultAuthor,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image:
          "https://images.ctfassets.net/ki6i38ru27a9/3jX82Wd2ibo6FoDxnKregp/da33f74f073ef7c4760c15e9648e4f83/logo.png?w=250&q=50&fm=webp",
        url: `${siteUrl}${pathname || "/"}`,
        author: author || defaultAuthor,
        keywords: keywords || ``,
      }

      return (
        <>
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seo.title}
            titleTemplate={titleTemplate}
          >
            <meta name="description" content={seo.description} />
            <meta name="theme-color" content="#aed2f4" />
            <meta name="image" content={seo.image} />
            <meta name="author" content={seo.author} />
            <meta name="robots" content="index, follow" />
            <meta name="keywords" content={keywords} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <link rel="canonical" href={seo.url} />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  author: PropTypes.string,
  keywords: PropTypes.string,
}

SEO.defaultProps = {
  lang: `en`,
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  author: ``,
  keywords: ``,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
        defaultAuthor: author
      }
    }
  }
`
